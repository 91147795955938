import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { QUESTION_ACTIONS,useFollowUp } from 'providers/FollowUpProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface SkipModalProps {
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
}

function SkipModal({
  isOpen,
  onOpen,
  onClose,
  value,
  setValue,
}: SkipModalProps) {
  const { t } = useTranslation()
  const { currentTask, dispatch } = useFollowUp()
  // const [value, setValue] = React.useState('understand')
  if(currentTask?.skipReason != undefined && currentTask.type === 'language_analysis')
    sessionStorage.setItem(currentTask.id+'-language',currentTask.skipReason)



    const handleCancel = () => {
        setValue('')
        dispatch({ type: QUESTION_ACTIONS.RESET_CURRENT_TASK, payload : ''})
        onClose()
    }


    const handleSubmit = () => {
        dispatch({ type: QUESTION_ACTIONS.RESET_CURRENT_TASK, payload : value})
       
        
        onClose()
    }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='black' pt='35' lineHeight='1.5'>
          {t('Skip_Modal_Title')}<br></br>
          {t('Skip_Modal_Subtitle')}
        </ModalHeader>
       <ModalCloseButton color='black' onClick={handleCancel}/>
        <ModalBody>
          <RadioGroup
            onChange={setValue}
            value={value}
            className='skip-options-radio'

          >
            <VStack alignItems='flex-start'>
              <Radio value='understand' colorScheme='pink' pb='3' lineHeight={'1.2'}>
                {t('Skip_Modal_Option1')}
              </Radio>
              <Radio value='distracted' colorScheme='pink' pb='3'  lineHeight={'1.2'}>
                {t('Skip_Modal_Option2')}
              </Radio>
              <Radio value='foreign' colorScheme='pink' pb='3'  lineHeight={'1.2'}>
                {t('Skip_Modal_Option3')}
              </Radio>
            
            </VStack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter  pb='35' >
          <Button
            onClick={handleCancel}
            mr='2'
            variant='outline'
            color='blue'
            bgColor='white'
            fontWeight='bold'
            borderColor='blue'
            border='2px solid'
            _hover={{ opacity: 0.7 }}
          >
           {t('Cancel_Button')}
          </Button>
          <Button onClick={handleSubmit}   isDisabled={!value}>{t('Submit_Button_Text')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SkipModal
function dispatch(arg0: { type: QUESTION_ACTIONS }) {
    throw new Error('Function not implemented.')
}

