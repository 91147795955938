import React, { ReactNode } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  useDisclosure,
  IconButton,
  HStack,
  Box,
  SpaceProps,
  BorderProps,
  BackgroundProps,
} from '@chakra-ui/react'
import CollapseIcon from 'common/CollapseIcon'
import { helpToolTipText } from '../../FollowUp/HelpToolTip'
import { BsInfoCircle } from 'react-icons/bs'
import ReadIcon from 'common/ReadIcon'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { CloseIcon } from '@chakra-ui/icons'

function HelpSideBarItem(props: {
  /** Used to override the title component */
  title?: ReactNode
  titleText?: string
  /** Used to override the body component */
  body?: ReactNode
  bodyText?: string
  mb?: SpaceProps['mb']
  p?: SpaceProps['p']
  borderRadius?: BorderProps['borderRadius']
  bgColor?: BackgroundProps['bgColor']
}) {
  const {
    title,
    titleText = 'Title',
    body,
    bodyText = 'Body',
    mb = '20px',
    p = '10px',
    borderRadius,
    bgColor,
  } = props

  return (
    <Box p={p} mb={mb} borderRadius={borderRadius} bgColor={bgColor}>
      {title !== undefined ? (
        title
      ) : (
        <Text mb='10px' textColor='black' fontWeight='bold'>
          {titleText}
        </Text>
      )}
      {body !== undefined ? (
        body
      ) : (
        <Text textColor='black' lineHeight='1.5' whiteSpace='pre-wrap'>
          {bodyText}
        </Text>
      )}
    </Box>
  )
}

function ImportantHelpSideBarItem(props: {
  title?: string
  body?: string
  mb?: SpaceProps['mb']
  icon?: ReactNode
}) {
  const {
    title = `${i18n.t('Read_Before_Completing_Text')}`,
    body = `${i18n.t('Welcome_Instructions1')}\n\n${i18n.t('Welcome_Instructions2')}`,
    mb = '30px',
    icon = <ReadIcon fill='black' />,
  } = props
  return (
    <HelpSideBarItem
      title={
        <HStack mb='10px' justifyContent='flex-start'>
          {icon}
          <Text textColor='black' fontWeight='bold'>
            {title}
          </Text>
        </HStack>
      }
      bodyText={body}
      mb={mb}
      p='20px'
      borderRadius='10px'
      bgColor='#ECE5FF'
    />
  )
}

export const HelpSideBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({})
  const { t } = useTranslation()
  return (
    <>
      <IconButton
        aria-label='menu-button'
        className='info-icon-menu'
        _focus={{
          border: 'none',
          boxShadow: 'none',
        }}
        _hover={{
          opacity: 0.6,
        }}
        icon={<BsInfoCircle fontSize='20px' />}
        variant='ghost'
        onClick={onOpen}
      />
      <Drawer placement='right' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bgColor='white' maxW='490px' borderLeftRadius='20px'>
          <DrawerHeader
            pb='4'
            px='0'
            paddingTop='30px'
            borderBottom='1px solid #ECEAF0'
          >
            <HStack
              alignItems='center'
              justifyContent='space-between'
              w='full'
              h='full'
              px='6'
            >
              <Text textColor='black' fontWeight='bold'>
                {t('Support_Sidebar_Title')}
              </Text>
              <DrawerCloseButton top='unset' borderRadius='full' boxSize='10'>
                <CloseIcon color='black' />
              </DrawerCloseButton>
            </HStack>
          </DrawerHeader>
          <DrawerBody px='6' py='5'>
            <ImportantHelpSideBarItem />
            <HelpSideBarItem
              titleText={`${t('Child_Not_Participate')}:`}
              bodyText={t('helpToolTipText')}
            />
            <HelpSideBarItem
              titleText={t('Child_Not_Responding_Title')}
              bodyText={t('Child_Not_Responding_Explanation')}
              {
                /* The above paragraph in parentheses should probably be rewritten, as it's a bit lengthy */ ...{}
              }
            />
            <HelpSideBarItem
              titleText={t('Indicates_Feeling_Title')}
              bodyText={t('Indicates_Feeling_Explanation')}
              {
                /* ??? */ ...{}
              }
              mb='0'
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
