import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { ImageSortingTask, PlacementPoint } from 'data-models/task-models'
import { Center,Image } from '@chakra-ui/react'
import ImagePlacementItem from './ImagePlacementItem'
import { useFollowUp } from 'providers/FollowUpProvider'


interface ImagePlacementPointProps {
  currentPoint: PlacementPoint
  onChange: (point: PlacementPoint, item: string) => void
  text: number | string 
  answerIndex: number
  //draggedItemProp?: string
}

export default function ImagePlacementPoint({
  onChange,
  currentPoint,
  answerIndex,
  text
}: ImagePlacementPointProps) {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImageSortingTask
  const [isSortShapes,setIsSortShapes] = useState(false)

  const borderWidth = task.name[1] === 'Shapes' ? '0' : '1px';
 
  const getCurrentImageSlug = (task: ImageSortingTask, answerIndex: number) => {
    const answer = task.answerOptions[answerIndex]
    const placedImageId = answer.placedAtPoint
 
 
  
    if (placedImageId) {
      const imageSlug = task.images.find(image => image.includes(placedImageId))
  
      return imageSlug
    }
    return null
  }
  const imageSlug = getCurrentImageSlug(task, answerIndex)

  const changeNewPoint = (item: any, _point: any) => {
    const placement = item.image.split('/assets/')[1]
    
   if(placement.includes('mathematics') || placement.includes('science')){
    onChange(currentPoint, placement.split('/')[1].split('.')[0])
   }
   else{
    onChange(currentPoint, placement.split('/')[1].split('-')[1].split('.')[0])
   }
  }

  const [_, drop] = useDrop(
    () => ({
      accept: 'image',
      drop: (item, monitor) => changeNewPoint(item, monitor.getClientOffset()),
    }),
    [currentPoint, imageSlug],
  )

  /// fetch the answers and set item url for the placedAtPoint not null'
  useEffect(()=> {
    if(currentTask.name[1] === 'Shapes'){
      setIsSortShapes(true)
    }
  },[currentTask])

  const finalText = text || ''
  return (
    <Center
      width='full'
      height='full'
      borderRadius='2xl'
      borderWidth={borderWidth}
      borderStyle='dashed'
      borderColor='white'
      color='rgba(255,255,255,0.25)'
      fontSize='48'
      fontWeight='semibold'
      ref={drop}
    >
      {
        isSortShapes ? 
        imageSlug ? <ImagePlacementItem ipa={imageSlug}/> : <Image src={String(text)} width={'80%'}/> 
        :
        imageSlug ? <ImagePlacementItem ipa={imageSlug} /> : finalText
        
      }
      {/* {imageSlug ? <ImagePlacementItem ipa={imageSlug} /> : finalText} */}
    </Center>
  )
}
