import {
  Button,
  Text,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Link,
  Box,
} from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ExternalLinkIcon } from '@chakra-ui/icons'

function ReleaseNotes() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { t } = useTranslation()
  const [latestRelease, setLatestRelease] = useState<any>(null)
  const fetchReleaseNotes = async () => {
    try {
      const response = await axios.get('/releaseNotes.json')
      const releaseData = response.data
      const latestRelease = releaseData.releases[0]
      checkVersion(latestRelease)
    } catch (error) {
      console.log('error', error)
    }
  }

  const checkVersion = latestRelease => {
    const currentVersion = localStorage.getItem('appVersion-portal')
    if (currentVersion !== latestRelease.version) {
      localStorage.setItem('appVersion-portal', latestRelease.version)
      setLatestRelease(latestRelease)
      onOpen()
    }
  }

  const closeModal = () => {
    onClose()
    if (latestRelease) {
      localStorage.setItem(
        `release_acknowledged_portal_${latestRelease.version}`,
        'true',
      )
    }
  }

  useEffect(() => {
    fetchReleaseNotes()
  }, [])

  return (
    <div>
      {latestRelease && (
        <Modal isOpen={isOpen} onClose={closeModal} isCentered size='2xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color={'black'}>
              {t('New_Release_Title')} (v{latestRelease.version})
            </ModalHeader>
            <ModalCloseButton color={'black'} />
            <ModalBody color={'black'} mb={5}>
              <Text color={'black'}>
                {t('Release_Date')}:{' '}
                {format(new Date(latestRelease.date), 'MMMM d, yyyy')}
              </Text>
              <Text color={'black'} fontWeight='bold' mt={4} mb={4}>
                {t('Feature_Title')}:
              </Text>
              <List spacing={3} color={'black'}>
              
                {latestRelease.features.map((feature, index) => (
                  <ListItem
                    key={index}
                    color={'black'}
                    lineHeight={1.2}
                    style={{
                      textIndent: '-10px',
                      paddingLeft: '20px',
                    }}
                  >
                    - {feature}
                  </ListItem>
                ))}
            
              </List>
              <Text fontWeight='bold' mt={4} mb={4} color={'black'}>
                {t('Bug_Fix_Title')}:
              </Text>
              <List spacing={3} color={'black'}>
                {latestRelease.fixes.map((fix, index) => (
                  <ListItem
                    key={index}
                    color={'black'}
                    lineHeight={1.2}
                    style={{
                      textIndent: '-10px',
                      paddingLeft: '20px',
                    }}
                  >
                    - {fix}
                  </ListItem>
                ))}
              </List>
              <Box display={'flex'} mt='20px'>
                <Text color={'black'}>{t('Read_More_Release')}</Text>
                <Link
                  href={'/release-notes'}
                  isExternal
                  pl='1'
                  fontWeight={700}
                  color='purple.700'
                >
                  {t('Read_More_Click')}
                  <ExternalLinkIcon
                    mx='2px'
                    verticalAlign='middle'
                    color='purple.700'
                  />
                </Link>
               
              </Box>
            </ModalBody>
            {/* <ModalFooter>
              <Button colorScheme='blue' onClick={closeModal}>
                Do not show again
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
      )}
    </div>
  )
}



export default ReleaseNotes
